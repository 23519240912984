import React, { Component } from "react";
import {  FormControl } from "react-bootstrap";
import myplexAPI from "../../api/myplexAPI";
// import { decryption } from "../../../commonfiles/encryption";
// import { decryption } from "./Encryption";
import queryString from "query-string";

import lensEvents from "../../LensAnalytics";
// import firebaseEvents from "../../common/firebaseEvents";

import { withRouter } from "react-router";
// import GoogleLogin from 'react-google-login';
// import { ConsoleView } from "react-device-detect";
// import { PhonePe } from "phonepesdk-web";
//import firebase from "../../analytics/firebase";
// import Calendar from 'react-calendar';
// import DatePicker from "react-datepicker";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";

// import moment from 'moment';

//import DatePicker from 'react-date-picker';
//import DatePicker from 'react-date-picker/dist/entry.nostyle';
// import DatePicker from 'react-date-picker/dist/entry.nostyle'

// import 'react-calendar/dist/Calendar.css';

// import  googleLogo from "../../assets/images/google_login_btn.png";
// import Modalview from "../Player/Modalview";
// import  facebookLogo from "../../assets/images/facebook_login_btn.png";
// import actionbar_back_icon from "../../assets/images/actionbar_back_icon.png";
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import apexheaderlogo from "../../assets/images/actionbar_brand_logo_hdpi.png"

class Login extends Component {
  constructor(props) {
    super(props);
    this.toastOptions = {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: 0,
    };

    // console.log(props)
    this.state = {
      getProfileDetails: [],
      emailValue: "",
      country: "",
      stateValue: "",
      gender: "",
      age: "",
      pincode: "",
      errormsgemail: "",
      errormsgusername: "",
      errormsgmobile: "",
      errormsgcountry: "",
      errormsggender: "",
      errormsgage: "",
      errormsgcreatepassword: "",
      errormsgconfirmpassword: "",
      errormsgsubmit: "",
      errormsgpincode: "",
      errormsgstate: "",
      errormsgaddress: "",
      showeditdiv: false,
      newdate: new Date(),
      showCalendar: false,
      countries: [],
      statelist: [],
      errormsgdob: "",
      
    };

    this.pincode = React.createRef();
    this.address = React.createRef();

    //  this.url = localStorage.getItem('redirectURL');
  }
  componentWillMount() { }
  componentDidMount() {
    // console.log(this);
    // this.getUserProfileDetails();
    // this.countries();
    // this.intializePhonepay();
    this.checkSSOLoginStatus();
  }

  getSsoClientId = () => {
    myplexAPI.getSsoAppInfo().then((response) => {
      // console.log(response)
      if (response.data.code === 200 && response.data.status === "SUCCESS") {
        
        localStorage.setItem("client_id", response.data.app_id);
        window.location.href = `https://id.manoramaonline.com/authorize?scope=openid+email+profile+offline_access+mobile&response_type=code+token+id_token&client_id=${response.data.app_id}&redirect_uri=${process.env.REACT_APP_DOMAINNAME}login&state=1135778640&nonce=212883085`;
      }
    });
  };

  checkSSOLoginStatus = () => {
    if (
      //(
        // localStorage.getItem("Logged-in") === undefined ||
        // localStorage.getItem("Logged-in") === null) &&
      window.location.hash !== "" &&
      window.location.hash.includes("mm.code")
    ) {
      //alert( queryString.parse(this.props.location.hash).id_token)
      //console.log(window.location.hash);
      localStorage.setItem(
        "token",
        queryString.parse(this.props.location.hash).access_token
      );
      localStorage.setItem(
        "id_token",
        queryString.parse(this.props.location.hash).id_token
      );
      this.myplexSSOLogin(
        queryString.parse(this.props.location.hash).code,
        queryString.parse(this.props.location.hash).id_token,
        queryString.parse(this.props.location.hash).access_token
      );
    }
    else if(window.location.hash.includes("error")){
      var url = localStorage.getItem("redirectUrl") || "/";
      this.props.history.push(url);
    }

    // else if (
    //   localStorage.getItem("Logged-in") &&
    //   window.location.pathname === "/login"
    // ) {

    //   this.getUserProfileDetails();

    // }
    else {
     // console.log("login redirect component login");
      this.getSsoClientId();
    }
  };

  countries = () => {
    myplexAPI.getCountryList().then((response) => {
      if (response && response.data && response.data.countries) {
        this.setState({ countries: response.data.countries });
      }
    });
  };

  getcountries = () =>
    this.state.countries.map((con, i) => {
      return (
        <option value={con.indexCode} key={i}>
          {con.name}
        </option>
      );
    });

  getStatelist = (code) => {
    myplexAPI.getStateList(code).then((response) => {
      //console.log(response);
      if (response && response.data && response.data.states) {
        this.setState({ statelist: response.data.states });
      }
    });
  };
  loadStatelist = () =>
    this.state.statelist.map((con, i) => {
      return (
        <option value={con.indexCode} key={i}>
          {con.name}
        </option>
      );
    });

  changeCountry = (event) => {
    var index = event.target.selectedIndex;
    this.setState(
      {
        country: event.target[index].value,
        stateValue: "",
      },
      this.getStatelist(event.target[index].value)
    );
  };
  changeState = (event) => {
    var index = event.target.selectedIndex;
    this.setState({ stateValue: event.target[index].value });
  };

  
  handleChange = (e) => {
   // console.log(e);
   // var dob = new Date(e);
    //calculate month difference from current date in time
   // var month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    //var age_dt = new Date(month_diff);

    //extract year from date
    //var year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    //var age = Math.abs(year - 1970);
   
    // window.moment(e.toLocaleDateString()).format('YYYY-MM-DD')

    this.setState({
      newdate: e,
      showCalendar: false,
    });
  };

  myplexSSOLogin = (mmtvid, idtoken, accesstoken) => {
   
    myplexAPI.ssoLogin(mmtvid, idtoken, accesstoken).then((res) => {
      //console.log("SSO login response ",res);
      if ((res.data.code === 200 || res.data.code === 201) && res.data.status === "SUCCESS") {
        
        localStorage.setItem("userIdLogin", res.data.userid);
       this.setState({getProfileDetails:res.data}) 

        localStorage.setItem("Logged-in", true);
        window.auth = true;
        var logindata = [];
        logindata["mobile_number"] = res.data.mobile_number;
        logindata["email"] = res.data.email;
        logindata["status"] = res.data.message;
        logindata["reason_for_failure"] = '';

        // firebaseEvents.login(logindata);
        lensEvents.logginfn(logindata);

       // this.getUserProfileDetails();
       var url = localStorage.getItem("redirectUrl") || "/";
       this.props.history.push(url);

      } else {
        // console.log("inside social login redirect");
        //this.getSsoClientId();
        this.props.history.push('loginFailed');
      }
    }).catch(function (err) {
        this.props.history.push('loginFailed');
    });;
  };

  // getUserProfileDetails = () => {
  //   myplexAPI.userLoginStatus().then((res) => {
  //    console.log(res);
  //     if (res.data.code === 200 && res.data.status === "SUCCESS") {
  //       if (
  //         res.data.result.profile.address === null ||
  //         res.data.result.profile.address === "" ||
  //         res.data.result.profile.dob === "" ||
  //         res.data.result.profile.dob === null ||
  //         res.data.result.profile.country === "" ||
  //         res.data.result.profile.country === null ||
  //         res.data.result.profile.state === "" ||
  //         res.data.result.profile.state === null ||
  //         res.data.result.profile.pincode === null ||
  //         res.data.result.profile.pincode === ""
  //       ) {
        
  //         this.countries();
  //         this.setState({
  //           showeditdiv: true,
  //           newdate:
  //             res.data.result.profile.dob !== ""
  //               ? new Date(res.data.result.profile.dob)
  //               : new Date(),
  //           stateValue: res.data.result.profile.hasOwnProperty("state")
  //             ? res.data.result.profile.state
  //             : "",
  //           country: res.data.result.profile.hasOwnProperty("country")
  //             ? res.data.result.profile.country
  //             : "",
  //         });
  //         this.address.value = res.data.result.profile.hasOwnProperty("address")
  //           ? res.data.result.profile.address
  //           : "";
  //         this.pincode.value = res.data.result.profile.hasOwnProperty("pincode")
  //           ? res.data.result.profile.pincode
  //           : "";
  //       }
  //        else {
  //         this.setState({
  //           showeditdiv: false,
  //         });

  //         var url = localStorage.getItem("redirectUrl") || "/";
  //         this.props.history.push(url);

  //         // window.location.assign("/");
  //       }
  //     }
  //   });
  // };
  changeGender = (event) => {
    var index = event.target.selectedIndex;
    this.setState({ gender: event.target[index].value });
  };
  registration = () => {
    if (this.state.country) {
      this.setState({ errormsgcountry: "" });
    } else {
      this.setState({ errormsgcountry: "Please select country" });
      return;
    }
    if (this.state.stateValue) {
      this.setState({ errormsgstate: "" });
    } else {
      this.setState({ errormsgstate: "Please select State" });
      return;
    }
    if (this.state.newdate) {
      this.setState({ errormsgdob: "" });
    } else {
      this.setState({ errormsgdob: "Please select  valid Date of Birth" });
      return;
    }

    if (this.address.value.length > 0) {
      this.setState({ errormsgaddress: "" });
    } else {
      this.setState({ errormsgaddress: "Please enter valid address" });
      return;
    }
    if (this.pincode.value.length > 0 && this.pincode.value.length === 6) {
      this.setState({ errormsgpincode: "" });

      this.submitSignupForm();
    } else {
      this.setState({ errormsgpincode: "Please enter valid pincode" });
      return;
    }
  };
  submitSignupForm = () => {
    myplexAPI
      .updateProfileDetails(
        this.state.country,
        this.state.stateValue,
        // window
        //   .moment(this.state.newdate.toLocaleDateString())
        //   .format("YYYY-MM-DD"),
        // this.address.value,
        // this.pincode.value
        null
      )
      .then((response) => {
        // console.log(response);

        if (response && response.data.code === 200) {
          ToastsStore.info("Your profile details Updated Successfully!");
           let countryName=this.state.countries.filter((value,i)=>{return value.indexCode===this.state.country});
           var updatedData={
            'signup_option' : 'email id',
            'mobile_number' : '',
            'email_id' :this.state.getProfileDetails.email ,
            'gender' : this.state.gender,
            'age' : this.state.newdate,
            'registered_country' : countryName.length>0?countryName[0]:'',
            'registered_state' : this.state.stateValue,
            'status' : 'Success',
            'reason_for_failure' : ''
        }
          lensEvents.signUp(updatedData);
          // toast("Your profile details Updated Successfully!", this.toastOptions);
          this.setState({ errormsgsubmit: "" });
          var url = localStorage.getItem("redirectUrl") || "/";
          this.props.history.push(url);
        } else if (response.code === 500) {
          this.setState({
            errormsgsubmit: response.message,
          });
        } else {
          this.setState({
            errormsgsubmit: response.data.message,
          });

          // }
        }
      });
  };

  render() {
    return (
      <section className="footer_details login_section">
        {this.state.showeditdiv && (
          <div>
            <h4 className="login_section_title">
              Please complete your profile
            </h4>

            <div className="form-group">
              <label className="field_title">Country</label>

              <select
                onChange={(evt) => {
                  this.changeCountry(evt);
                }}
                value={this.state.country}
              >
                <option value={""}>Select Country</option>

                {this.state.countries.length > 0 && this.getcountries()}
          
              </select>
              <p className="error_msg">{this.state.errormsgcountry}</p>
            </div>
            <div className="form-group">
              <label className="field_title">State</label>

              <select
                onChange={(evt) => {
                  this.changeState(evt);
                }}
                value={this.state.stateValue}
              >
                <option value={""}>Select State</option>
                {this.state.statelist.length > 0 && this.loadStatelist()}
             
              </select>
              <p className="error_msg">{this.state.errormsgstate}</p>
            </div>

            <div className="form-group">
              <label className="field_title">D.O.B</label>
              {/* <DatePicker
                selected={this.state.newdate}
                onChange={(e) => this.handleChange(e)}
                dateFormat="yyyy/MM/dd"
                maxDate={new Date()} 
                showMonthDropdown showYearDropdown
              /> */}
            
            </div>
            <div className="form-group">
              <label className="field_title">Address</label>
              <FormControl
                className="form-control textarea_radius"
                type="text"
                placeholder="Enter Address"
                inputRef={(ref) => {
                  this.address = ref;
                }}
              />
              <p className="error_msg">{this.state.errormsgaddress}</p>
            </div>

            <div className="form-group">
              <label className="field_title">Pincode</label>
              <FormControl
                type="text"
                placeholder="Enter Pincode"
                inputRef={(ref) => {
                  this.pincode = ref;
                }}
              />
              <p className="error_msg">{this.state.errormsgpincode}</p>
            </div>

            <div className="form-group login_btn">
              <button type="button" onClick={() => this.registration()}>
                Update
              </button>
            </div>

            <p className="error_msg">{this.state.errormsgsubmit}</p>
          </div>
        )}
        {/* <ToastContainer /> */}
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.BOTTOM_CENTER}
        />
      </section>
    );
  }
}

export default withRouter(Login);
