import React, { Component } from "react";
import Img from "../Uicomponents/Img";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { Link } from "react-router-dom";
import myplexAPI, { URL_CAROUSELCONTENT, URL_MENU } from "../../api/myplexAPI";
import "./Carousel.css";
import ParseImgURL from "../../utils/parseImgURL";

// import secureImages from "../../utils/secureImages";
//import secureImages from "../../../commonfiles/secureImages";

import indexedDB from "../../utils/indexedDB";
// import { slugify } from "../../utils/stringSlugify";
import { slugify } from "../../../commonfiles/stringSlugify";

//import { carouselPatnerLogos } from "../../utils/partnerLogos";
//import LazyLoad from "react-lazyload";
// import { getURLName } from "../../utils/urlMenu";
import { getURLName } from "../../../commonfiles/urlMenu";
import premium_badge_lock from "../../assets/images/premium_badge_lock.png";

import { withRouter } from "react-router";
 //import placeholder_vertical from "../../assets/images/videos___shows_placeholder.png";
import { isMobile } from "react-device-detect";

//import carousel_freetag from "../../assets/images/freetag_thumbnail.png";
// import ParseImgURL from "../../utils/parseImgURL";
import sessionFun from "../../../commonfiles/SessionStorage"

class BreakingNews extends Component {
  list = "";
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      caroselimages: [],
      pageName: "home",
      trackingId: "",
    };
    this.carousel_info_name = this.props.caroselinfo.name;
    this._isMounted = false;
    this.auth = localStorage.getItem("Logged-in");
  }
  componentDidMount() {
    this._isMounted = true;

    const page_name = window.location.pathname.split("/")[1];
    if (page_name !== "") {
      this.setState({
        pageName: page_name,
      });
      // if (page_name === 'Watch-History') {
      //   this.getSsoClientId();

      // }
    }
    this.loadCarouselsData();
  }
  componentDidUpdate(prevsprops) {
    if (this.props.caroselinfo.name !== prevsprops.caroselinfo.name) {
      this.loadCarouselsData();
    }
  }
  componentWillUnmount() {
    localStorage.removeItem("Languagechecklist", "");

    this._isMounted = false;
  }
  
  loadCarouselsData() {
    // eslint-disable-next-line
    {
      this.carousel_info_name !== "portalMobile_Discovery" &&
        this.carousel_info_name !== "portaldiscovery" &&
        this.carousel_info_name !== "portalMobileDiscovery" &&
        this.carousel_info_name !== "Portal_popular_genres" &&
        this.carousel_info_name !== "Portal_popular_languages"
        ? this._getCarouselsData(1)
        : this._getCarouselsData(0);
    }
  }
  _getCarouselsData = (e) => {
    const that = this;
    let url = "";
    const carousel_name = this.props.caroselinfo.name;
    // console.log(carousel_name)
    const network = window.navigator.onLine;
    if (e === 1) {
      url = URL_CAROUSELCONTENT(carousel_name, 1, 10,this.props.caroselinfo.modified_on);
    } else {
      url = URL_MENU(carousel_name);
    }
    // console.log(carousel_name,carousel_name.match(/MyReco/g))
    if (
      carousel_name !== "continue_watching_portal_mobile" ||
      carousel_name !== "IMT_portal_continue_watching" ||
      carousel_name !== "portal_continue_watching" ||
      carousel_name.match(/MyReco/g) === null
    ) {
      // console.log(carousel_name)
      indexedDB(url).then(function (response) {
        if (response !== false && that._isMounted) {
          that.setState({
            caroselimages: response,
          });
        } else {
          that._fetchCarousels(e, carousel_name);
        }
      });
    } else {
      if (network === true) {
        this._fetchCarousels(e, carousel_name);
      }
    }
  };
  _fetchCarousels = (e, carousel_name) => {
    const that = this;
    if (e === 1) {
      if (
        carousel_name === "continue_watching_portal_mobile" ||
        carousel_name === "IMT_portal_continue_watching" ||
        carousel_name === "portal_continue_watching" ||
        carousel_name.match(/MyReco/g) !== null
      ) {
        myplexAPI
          .getContinueWatchingData(carousel_name, 1, 10)
          .then((response) => {
            // console.log(carousel_name, response);

            if (response !== null) {
              if (response.data !== null && this._isMounted) {
                that._responseData(response, 1);
                this.setState({
                  trackingId: response.data.trackingId,
                });
              }
            }
          });
      } else {
        this.list = localStorage.getItem("selectedLanguageList");
        myplexAPI
          .getCarouselData(carousel_name, 1, 10, this.props.caroselinfo.modified_on)
          .then((response) => {
            // console.log(carousel_name, response);
            that._responseData(response);
          });
      }
    } else {
      this.list = localStorage.getItem("selectedLanguageList");
      this.appLanguage = localStorage.getItem("selectedAppLanguageList");

      myplexAPI
        .pullPageData(carousel_name, this.list, this.appLanguage)
        .then((response) => {
          // console.log(response);
          that._responseData(response);
        });
    }
  };
  _responseData = (response, flag = 0) => {
    // console.log(response.data.status)
    if (
      response.data &&
      response.data.status &&
      response.data.status === "SUCCESS" &&
      this._isMounted
    ) {
      this.setState({
        caroselimages: response.data.results,
      });
      if (flag === 0) {
        myplexAPI.storeIndexedDb(
          response.request.responseURL,
          response.data.results
        );
      }
    }
  };
  decodeHTMLEntities = (text) => {
    var entities = {
      amp: "&",
      apos: "'",
      lt: "<",
      gt: ">",
      quot: '"',
      nbsp: "\xa0",
    };
    var entityPattern = /&([a-z]+);/gi;

    return text.replace(entityPattern, function (match, entity) {
      entity = entity.toLowerCase();
      if (entities.hasOwnProperty(entity)) {
        return entities[entity];
      }

      // return original string if there is no matching entity (no replace)
      return match;
    });
  };

  carouselClick = (i, id) => {
  // console.log(URL);

    sessionFun(this.props.carousel_count,i+1,this.state.pageName,'carosuel',this.props.caroselinfo.title,id);
    // var src;
    // if (isMobile) {
    //   src = ParseImgURL(images.values, "mdpi", "coverposter"); //images.values[0].link;
    // } else {
    //   src = ParseImgURL(images.values, "xxhdpi", "coverposter");
    // }

    // localStorage.setItem("actorImage", src);
    // localStorage.setItem("actorType", type);
    // localStorage.setItem("personDecription", description);

    // if (this.auth) {
    //   if (type !== "person" && type !== "actor") {
    //     this.props.history.push(URL);
    //   } else {
    //     this.props.history.push(`/Actorprofile/${title}`);
    //   }
    // } else {
    //   if (type !== "person" && type !== "actor") {
    //     localStorage.setItem("redirectUrl", URL);
    //     this.props.history.push(URL);

    //     // this.props.history.push("/login");
    //   } else {
    //     this.props.history.push(`/Actorprofile/${title}`);
    //   }

    //   //  this.props.history.push("/login");
    // }
  };
  viewall = () => {
    localStorage.setItem("texture", JSON.stringify(this.props.texture));
  };
  caroselimages = () => {
    // return this.props.layoutType !== "nestedCarousel"
    //   ?
   
      return  this.state.caroselimages.map((caroselimg, i) => {
       
        // let logo = null;
        // if (caroselimg.publishingHouse !== undefined) {
        //   let partnerLogos = carouselPatnerLogos(
        //     caroselimg.publishingHouse.publishingHouseName
        //   );
        //   if (
        //     partnerLogos.link !== undefined &&
        //     this.props.layoutType !== "horizontalListSmallItem" &&
        //     this.props.layoutType !== "horizontalListMediumItem"
        //   ) {
        //     logo = (
        //       <LazyLoad once>
        //         <img
        //           src={partnerLogos.link}
        //           alt={partnerLogos.name}
        //           className="carousel_logo_patner"
        //         />
        //       </LazyLoad>
        //     );
        //   }
        // }
        // sessionFun(this.props.carousel_count,i+1,this.state.pageName,'carosuel',this.props.caroselinfo.title,caroselimg.generalInfo._id);
       
        return (
          <Link
          to={{
           
              pathname: `/${getURLName(
              caroselimg,
              this.state.pageName
            )}/detail/${caroselimg.generalInfo._id}/${slugify(caroselimg.generalInfo.title)}`
            // )}

             
          }}
          onClick={() =>  this.carouselClick(i,caroselimg.generalInfo._id)}
          key={i}
        >
          <div
            className="carousel_img"
            // onClick={() =>
            //   this.carouselClick(
            //     `/${getURLName(caroselimg, this.state.pageName)}/detail/${caroselimg.generalInfo._id
            //     }/${slugify(caroselimg.generalInfo.title)}`,
            //     caroselimg.generalInfo.type,
            //     caroselimg.generalInfo.title,
            //     caroselimg.images,
            //     caroselimg.generalInfo.description
            //   )
            // }
            key={i}
          >
            {/* {!caroselimg.generalInfo.isSellable &&
              caroselimg.generalInfo.contentRights &&
              caroselimg.generalInfo.contentRights[0] === "avod" && (
                <div className="carousel_freetag">
                  <img src={carousel_freetag} alt="carousel_freetag" />
                </div>
              )} */}
            <Img
              caroselimg={caroselimg}
              key={i}
              banner={true}
              layouttype={this.props.layoutType}
              caroselname={this.props.caroselinfo.name}
              observer={true}
            />
             {caroselimg.generalInfo.accessLabel && sessionStorage.getItem("userPacks") != null && JSON.parse(sessionStorage.getItem("userPacks")).length <= 0 &&
             <div className="premium_badge_lock">
                <img src={premium_badge_lock} alt="premium_badge_lock" />
              </div>}
          
              { this.props.caroselinfo.showTitle &&
            <div className="breaking_caption">
              <p>{caroselimg.generalInfo.description}</p>
            </div>
      }
            {/* {this.props.texture !== null && this.props.texture.length > 0 && (
              <div className="carousel_titles">
                {this.props.texture.find(
                  (obj) => obj.metadata === "title"
                ) !== null &&
                  this.props.texture.find((obj) => obj.metadata === "title") !==
                  undefined ? (
                  <h1 className="movie_title">
                    {caroselimg.generalInfo.title}
                  </h1>
                ) : (
                  // <h1></h1>
                  null
                )}

                <div className="movie_details-div">
                  <ul>
                    {this.props.texture.find(
                      (obj) => obj.metadata === "language"
                    ) !== null &&
                      this.props.texture.find(
                        (obj) => obj.metadata === "language"
                      ) !== undefined &&
                      caroselimg.content.language.length > 0 ? (
                      <li>{caroselimg.content.language[0]}</li>
                    ) : (
                      ""
                    )}
                    {this.props.texture.find(
                      (obj) => obj.metadata === "genre"
                    ) !== null &&
                      this.props.texture.find(
                        (obj) => obj.metadata === "genre"
                      ) !== undefined &&
                      caroselimg.content.genre.length > 0 ? (
                      <li>{caroselimg.content.genre[0].name}</li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
                {this.props.texture.find(
                  (obj) => obj.metadata === "rating"
                ) !== null &&
                  this.props.texture.find(
                    (obj) => obj.metadata === "rating"
                  ) !== undefined ? (
                  <div className="app_rating">
                    {parseInt(caroselimg.content.contentRating) > 0 ? (
                      <StarRatings
                        rating={parseInt(caroselimg.content.contentRating)}
                        starRatedColor="#ffcc33"
                        // changeRating={this.changeRating}
                        numberOfStars={5}
                        name="star-container"
                        className="rated"
                      />
                    ) : (
                      <ul id="star-rating">
                        <li className="rated"></li>
                        <li className="rated"></li>
                        <li className="rated"></li>
                        <li className="rated"></li>
                        <li></li>
                      </ul>
                    )}
                  </div>
                ) : (
            
                  <div className="app_rating"></div>
                )}
               
                {caroselimg.generalInfo.isSellable &&
                  this.props.texture.find(
                    (obj) => obj.metadata === "price"
                  ) !== null &&
                  this.props.texture.find(
                    (obj) => obj.metadata === "price"
                  ) !== undefined && (
                    <h1 className="payment_amount rupee">
                      {caroselimg.packages.length > 0 &&
                        caroselimg.packages[0].priceDetails.length > 0 ? (
                        <span className="rupee_symbol">
                          ₹ {caroselimg.packages[0].priceDetails[0].price}
                        </span>
                      ) : (
                        ""
                      )}
                    </h1>
                  )}
                {!caroselimg.generalInfo.isSellable &&
                  caroselimg.generalInfo.contentRights &&
                  caroselimg.generalInfo.contentRights[0] === "avod" &&
                  this.props.texture.find(
                    (obj) => obj.metadata === "price"
                  ) !== null &&
                  this.props.texture.find(
                    (obj) => obj.metadata === "price"
                  ) !== undefined && (
                    <h1 className="payment_amount rupee">
                      <span className="rupee_symbol">Free</span>
                    </h1>
                  )}
                
              </div>
            )} */}

          
          </div>
          </Link>
        );
      })
    
  };
  checkFilterValues = (title) => {
    return this.carousel_info_name === "Portal_popular_genres"
      ? `?type=movie&genres=${title}`
      : `?type=movie&filtervalue=${title}`;
  };
  getThumbnailLiveTv = (item) => {
    //console.table(item.images.values);
    return ParseImgURL(item.images.values, "mdpi", "thumbnail");
  };
  getPartnerLogo = (array) => {
    return array.find(function (e) {
      return e.profile === "hdpi";
    });
  };
  getclassName = (link) => {
    // var result = link.find(function(e) {
    //   return e.profile === "hdpi";
    // });
    var result = this.getPartnerLogo(link);
    let imgclass;
    let issquare = result.link.includes("square");
    let ishook = result.link.includes("hook");
    let ishungama = result.link.includes("hungama");
    let isarre = result.link.includes("arre");
    if (issquare) {
      imgclass = "squareimage";
    } else if (ishook) {
      imgclass = "hookimage";
    } else if (ishungama) {
      imgclass = "hungamaimage";
    } else if (isarre) {
      imgclass = "arreimage";
    } else {
      imgclass = "logo";
    }
    return imgclass;
  };
  sourceDetails = (source_details, trackingId) => {
    // console.log(trackingId)
    // console.log(source_details)
    localStorage.setItem("source details", source_details);
    localStorage.setItem("source", "carousel");
    // localStorage.setItem("trackingId", trackingId);
   
  };

  render() {
    
    // let perpage = 0;

    // if (
    //   this.props.layoutType === "horizontalListBigItem" ||
    //   this.props.layoutType === "previewCarousel"||
    //   this.props.layoutType==="roundedArtistCarousel"
    // ) {
    //   perpage = 10;
    // } else if (this.props.layoutType === "nestedCarousel") {
    //   perpage = 8;
    // } else {
    //   perpage = 7;
    // }

    var items,center;
    if(isMobile) {
       items= 1.2;
       center = true;
    }else {
      items = 2;
      center = true;
    }

    const settings = {
        centerMode: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1.65,
        slidesToScroll:1,
        // initialSlide: -1,
        dots: true,
        arrows: true,

        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll:1,
              arrows: false,
              dots: false
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll:1,
              arrows: false,
              dots: false
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll:1,
              arrows: false,
              dots: false
            },
          },
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 1,
              slidesToScroll:1,
              arrows: false,
              dots: false
            },
          },
        ],
      };

    // const options = {
    //   items: items,
    //   center: center,
    //   nav: true,
    //   navText: [
    //     "<div class='carousel-nav-btn carousel-prev-slide'></div>",
    //     "<div class='carousel-nav-btn carousel-next-slide'></div>",
    //   ],
    //   rewind: true,
    //   margin: 10,
    //   dots: true,
    //   autoplay: false,
    //   slideBy: 1,
    //   loop: true,
    //   touchDrag: true,
    //   mouseDrag: true,
    // };

    const events = {
      onDragged: function (event) { },
      onChanged: function (event) { },
    };
    // console.log(this.props.caroselinfo)
    let title = this.props.caroselinfo.title;
    if (this.props.caroselinfo.altTitle) {
      title = this.props.caroselinfo.altTitle;
    }
    // console.log(title)
    return (
      <React.Fragment>
        {this.state.caroselimages.length > 0 && (
          <div className="breaking_news_carousel layout_center_banner">
           <div className="carousel_slider_section">
            <div className="carousel_body" id={this.props.carousel_count}>
             <div className="home_carosel_title" onClick={() => this.viewall()}>
              
                <h1 style={{ cursor: "default" }}>{title}</h1>
             
            
            </div>
            <div
              className="carosel_slider"
              onClick={() =>
                this.sourceDetails(
                  this.props.caroselinfo.title,
                  this.state.trackingId
                )
              }
            >
                <Slider {...settings}> {this.caroselimages()} </Slider>
              {/* <OwlCarousel ref="car" options={options} events={events}>
                {this.caroselimages()}
              </OwlCarousel> */}
            </div>
          </div>
          </div>
          </div>
        )}
        {this.state.caroselimages.length === 0 && (
          <div id={this.props.carousel_count} />
        )}
      </React.Fragment>
    );
  }
}
export default withRouter(BreakingNews);
