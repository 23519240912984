import React, {Component} from "react";
import ParseImgURL from "../../utils/parseImgURL";
import bannerimage from "../../assets/images/banner_placeholder.jpg";
import {Link} from "react-router-dom";
import Popupmodal from "../Header/popupmodal";
import {isPremimumUser} from "../../../../src/manorama/utils/portalhelper";
export default class Promobanner extends Component {
	constructor(props) {
		super(props);
        this.setpopup = this.setPopUpState.bind(this);
		this.state = {
			popup: false,
            pwaDemographicConfig:"",
            showCarousel:true
		};
    }
    componentDidMount() {
        this.showDemographicCariusel();
    }
    setPopUpState = (state) => {
        this.setState({popup:state})
    }
    errorimg = (e) => {
        e.target.src = bannerimage
    };

    singleBanner = () => {
        return ParseImgURL(this.props.caroselinfo.images, "xxhdpi", "icon");
    };
    showLoginPopUp = () =>{
        document.getElementById("profile_img").click();
    };
	readCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(";");
		for (var i = 0; i < ca.length; i++) {
		  var c = ca[i];
		  while (c.charAt(0) === " ") c = c.substring(1, c.length);
		  if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}
    showDemographicPopup = () => {
        this.demoGraphicPopup(true);
    }
    demoGraphicPopup = (status) => {
        var showPopupByCount = false;
        var demoGraphicPopupDisplayData = JSON.parse(localStorage.getItem("demoGraphicPopupShoweddata"));
        var properties = JSON.parse(sessionStorage.getItem('properties'));
        var isNotPremimumUser = isPremimumUser();
        if(properties && properties.pwaDemographicConfig){
          var pwaDemographicConfig = JSON.parse(properties.pwaDemographicConfig);
          //console.log("pwaDemographicConfig ",pwaDemographicConfig);
          this.setState({pwaDemographicConfig : pwaDemographicConfig});
          if(pwaDemographicConfig.profileUpdate_popup_enabled){
            if(demoGraphicPopupDisplayData !== undefined && demoGraphicPopupDisplayData !== null && demoGraphicPopupDisplayData.count === pwaDemographicConfig.profileUpdate_popup_later_limit){
                showPopupByCount = true;
            }
            if(localStorage.getItem("demographicData") === undefined || localStorage.getItem("demographicData") === null && showPopupByCount && isNotPremimumUser){
                this.setState({popup:status});
            }
            
          }
        }
    }
    showDemographicCariusel = () => {
        //console.log("showDemographicCariusel");
        var demoGraphicPopupDisplayData = JSON.parse(localStorage.getItem("demoGraphicPopupShoweddata"));
        var properties = JSON.parse(sessionStorage.getItem('properties'));
        var pwaDemographicConfig = properties && JSON.parse(properties.pwaDemographicConfig);
        if(this.props.caroselinfo.appAction === 'demographic_update'){
            var date = new Date();
            var month = date.getMonth() + 1;
            var todayDate = date.getDate()+'-'+month+'-'+date.getFullYear();
            var demoGraphicPopupLocalShoweddata = {};
            if(localStorage.getItem("demoGraphicPopupShoweddata") !== undefined && localStorage.getItem("demoGraphicPopupShoweddata") !== null){
                demoGraphicPopupLocalShoweddata = JSON.parse(localStorage.getItem("demoGraphicPopupShoweddata"));
            }
            // if(demoGraphicPopupDisplayData !== undefined && demoGraphicPopupDisplayData !== null && demoGraphicPopupDisplayData.count === pwaDemographicConfig.profileUpdate_popup_later_limit){
            if(localStorage.getItem("demoGraphicShowBanner") !== undefined && localStorage.getItem("demoGraphicShowBanner") !== null && localStorage.getItem("demoGraphicShowBanner") === 'true' && (localStorage.getItem("demographicData") === undefined || localStorage.getItem("demographicData") === null) && demoGraphicPopupLocalShoweddata.date !== todayDate){
                this.setState({showCarousel:true});
            }else{
                this.setState({showCarousel:false});
            }
        }
        
    }
    render() {
       
        const image =   <img src={this.singleBanner()} alt="Smiley face" height="42" width="42" style={{
            width: "100%",
            height: "100%"
        }} onError={e => this.errorimg(e)}/>;
        return (
            <React.Fragment>
            {this.state.showCarousel &&
            <div className="promoStaticBanner" id={this.props.caroselinfo.appAction === 'demographic_update' ? 'demographic' :'promobanner'}>
                {this.props.caroselinfo?.showTitle && (this.props.caroselinfo.showTitle === 'true' || this.props.caroselinfo.showTitle === true) && <h1>{this.props.caroselinfo.title}</h1> }
                {/* {(localStorage.getItem("Logged-in") || this.readCookie("logged-in")) ?   */}
                {this.props.caroselinfo.appAction==="Subscribe" ?  <Link
                        to={{pathname: `/subscribe/${this.props.caroselinfo.actionUrl}`}}
                        >
                          {image}
                    </Link> : (
                        this.props.caroselinfo.appAction === 'demographic_update' ? 
                        <div onClick={ () => this.showDemographicPopup() }>
                            {image}
                        </div>
                        :
				 <a href={this.props.caroselinfo.actionUrl}>
                    {image}
                </a> )
    }
                 {/* : <div onClick={ this.showLoginPopUp }>{image}</div> */}
                 {/* } */}
            </div>
            }
            {this.state.popup && this.props.caroselinfo.appAction === 'demographic_update' &&<Popupmodal pwaDemographicConfig={this.state.pwaDemographicConfig}  showPopup = {this.state.popup} setpopup={this.setpopup} pageType="carousel" />}
            </React.Fragment>
        )
    }
}
