import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import view_all_arrow_mm from "../../assets/images/view_all_arrow_mm.png";
import myplexAPI, { URL_CAROUSELCONTENT } from "../../api/myplexAPI";
import "./Carousel.css";
import ParseImgURL from "../../utils/parseImgURL";
// import secureImages from "../../../commonfiles/secureImages";
import indexedDB from "../../utils/indexedDB";
import { slugify } from "../../../commonfiles/stringSlugify";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
// import placeholder_vertical from "../../assets/images/videos___shows_placeholder.png";
import { isMobile } from "react-device-detect";
import sessionFun from "../../../commonfiles/SessionStorage"
import Img from "../Uicomponents/Img";
//import StarRatings from "react-star-ratings";
import { getURLName } from "../../../commonfiles/urlMenu";

class RadioCarousel extends Component {


  list = "";
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      caroselimages: [],
      pageName: "home",
      trackingId: "",
    };
    this.carousel_info_name = this.props.caroselinfo.name;
    this._isMounted = false;
    this.auth = localStorage.getItem("Logged-in");
  }
  componentDidMount() {
    this._isMounted = true;

    const page_name = window.location.pathname.split("/")[1];
    if (page_name !== "") {
      this.setState({
        pageName: page_name,
      });

    }
    this.loadCarouselsData();
  }
  componentDidUpdate(prevsprops) {
    if (this.props.caroselinfo.name !== prevsprops.caroselinfo.name) {
      this.loadCarouselsData();
    }
  }
  loadCarouselsData() {
    this._getCarouselsData()
  }
  _getCarouselsData = () => {
    const that = this;
    let url = "";
    const carousel_name = this.props.caroselinfo.name;
    url = URL_CAROUSELCONTENT(carousel_name, 1, 10, this.props.caroselinfo.modified_on);
    indexedDB(url).then(function (response) {
      if (response !== false && that._isMounted) {
        that.setState({
          caroselimages: response,
        });
      } else {
        that._fetchCarousels(carousel_name);
      }
    });

  };
  _fetchCarousels = (carousel_name) => {
    const that = this;

    this.list = localStorage.getItem("selectedLanguageList");
    myplexAPI
      .getCarouselData(carousel_name, 1, 10, this.props.caroselinfo.modified_on)
      .then((response) => {
        // console.log(carousel_name, response);
        that._responseData(response);
      });
  }

  _responseData = (response, flag = 0) => {
    // console.log(response.data.status)
    if (
      response.data &&
      response.data.status &&
      response.data.status === "SUCCESS" &&
      this._isMounted
    ) {
      this.setState({
        caroselimages: response.data.results,
      });
      if (flag === 0) {
        myplexAPI.storeIndexedDb(
          response.request.responseURL,
          response.data.results
        );
      }
    }
  }
  decodeHTMLEntities = (text) => {
    var entities = {
      amp: "&",
      apos: "'",
      lt: "<",
      gt: ">",
      quot: '"',
      nbsp: "\xa0",
    };
    var entityPattern = /&([a-z]+);/gi;

    return text.replace(entityPattern, function (match, entity) {
      entity = entity.toLowerCase();
      if (entities.hasOwnProperty(entity)) {
        return entities[entity];
      }

      // return original string if there is no matching entity (no replace)
      return match;
    });
  }
  carouselClick = (URL, type, title, images, description) => {
    var src;
    if (isMobile) {
      src = ParseImgURL(images.values, "mdpi", "coverposter"); //images.values[0].link;
    } else {
      src = ParseImgURL(images.values, "xxhdpi", "coverposter");
    }
    localStorage.setItem("actorImage", src);
    localStorage.setItem("actorType", type);
    localStorage.setItem("personDecription", description);
    if (this.auth) {
      if (type !== "person" && type !== "actor") {
        this.props.history.push(URL);
      } else {
        this.props.history.push(`/Actorprofile/${title}`);
      }
    } else {
      if (type !== "person" && type !== "actor") {
        localStorage.setItem("redirectUrl", URL);
        this.props.history.push(URL);

        // this.props.history.push("/login");
      } else {
        this.props.history.push(`/Actorprofile/${title}`);
      }
    }
  }
  viewall = () => {
    localStorage.setItem("texture", JSON.stringify(this.props.texture));
  }
  caroselimages = () => {
    return this.state.caroselimages.map((caroselimg, i) => {

      sessionFun(this.props.carousel_count, i + 1, this.state.pageName, 'carosuel', this.props.caroselinfo.title, caroselimg.generalInfo._id);

      return (

        <div onClick={() =>
          this.carouselClick(
            `/${getURLName(caroselimg, this.state.pageName)}/detail/${caroselimg.generalInfo._id
            }/${slugify(caroselimg.generalInfo.title)}`,
            caroselimg.generalInfo.type,
            caroselimg.generalInfo.title,
            caroselimg.images,
            caroselimg.generalInfo.description
          )
        }
          key={i} >


          <div className="profile_radio_section">
            <Img
              caroselimg={caroselimg}
              key={i}
              banner={false}
              layouttype={this.props.layoutType}
              caroselname={this.props.caroselinfo.name}
              observer={true}
              myCustomClass='profile_radio_circle'
            />

            <h4>{caroselimg.generalInfo.title}</h4>

          </div>


          {this.props.texture !== null && this.props.texture.length > 0 && (
            <div className="carousel_titles">

              {this.props.texture.find(
                (obj) => obj.metadata === "rating"
              ) !== null &&
                this.props.texture.find(
                  (obj) => obj.metadata === "rating"
                ) !== undefined ? (
                <div className="app_rating">

                </div>
              ) : (

                <div className="app_rating"></div>
              )}

            </div>
          )}


        </div>
      );
    })

  }
  checkFilterValues = (title) => {
    return this.carousel_info_name === "Portal_popular_genres"
      ? `?type=movie&genres=${title}`
      : `?type=movie&filtervalue=${title}`;
  };



  render() {
    let title = this.props.caroselinfo.title;
    if (this.props.caroselinfo.altTitle) {
      title = this.props.caroselinfo.altTitle;
    }
    let perpage = 0;

    if (
      this.props.layoutType === "horizontalListBigItem" ||
      this.props.layoutType === "previewCarousel" ||
      this.props.layoutType === "roundedArtistCarousel"
    ) {
      perpage = 10;
    } else if (this.props.layoutType === "nestedCarousel") {
      perpage = 8;
    } else {
      perpage = 7;
    }

    const options = {
      items: perpage,

      responsive: {
        320: {
          items: perpage === 10 ? 2 : perpage === 8 ? 4 : 2,
          nav: true,
        },
        360: {
          items: perpage === 10 ? 3 : perpage === 8 ? 4 : 2,
          nav: true,
        },
        481: {
          items: perpage === 10 ? 3 : perpage === 8 ? 4 : 3,
          nav: false,
        },
        768: {
          items: perpage === 10 ? 4 : perpage === 8 ? 5 : 4,
          nav: false,
        },
        1200: {
          items: perpage === 10 ? 6 : perpage === 8 ? 5 : 5,
          nav: true,
        },
        1300: {
          items: perpage === 10 ? 7 : perpage === 8 ? 6 : 7,
          nav: true,
        },
        1400: {
          items: perpage === 10 ? 8 : perpage === 8 ? 6 : 8,
          nav: true,
        },
        1500: {
          items: perpage === 10 ? 8 : perpage === 8 ? 6 : 8,
          nav: true,
        },
        1600: {
          items: perpage === 10 ? 9 : perpage === 8 ? 7 : 8,
          nav: true,
        },
        1700: {
          items: perpage === 10 ? 10 : perpage === 8 ? 7 : 8,
          nav: true,
        },
      },
      // nav: true,
      navText: [
        "<div class='carousel-nav-btn carousel-prev-slide'></div>",
        "<div class='carousel-nav-btn carousel-next-slide'></div>",
      ],
      rewind: false,
      margin: 10,
      dots: false,
      autoplay: false,
      slideBy: 10,
      loop: false,
      touchDrag: true,
      mouseDrag: true,
    };

    const events = {
      onDragged: function (event) { },
      onChanged: function (event) { },
    };
    return (
      <div className="profile_radio_carousel">
        <div className="carousel_body">
          <div className="home_carosel_title">
            {this.state.caroselimages.length > 0 &&
              <Link
                to={{
                  pathname: `/${this.state.pageName}/inside/${this.props.caroselinfo.name}`,
                  search: `?name=${this.props.caroselinfo.title.replace(
                    / /g,
                    "-"
                  )}&layoutType=${this.props.layoutType}&title=${title}`,
                }}
              ><h1>{this.decodeHTMLEntities(title)}</h1></Link>}

            {this.state.caroselimages.length > 0 &&
              <Link
                to={{
                  pathname: `/${this.state.pageName}/inside/${this.props.caroselinfo.name}`,
                  search: `?name=${this.props.caroselinfo.title.replace(
                    / /g,
                    "-"
                  )}&layoutType=${this.props.layoutType}&title=${title}`,
                }}>
                <img src={view_all_arrow_mm} className="view_all_arrow" alt="viewall_arrow_img" /></Link>}

          </div>
          {this.state.caroselimages.length > 0 &&
          <OwlCarousel ref="car" options={options} events={events} >
           
            {this.caroselimages()}
          
          </OwlCarousel> }

        </div>
      </div>
    );
  }
}
export default withRouter(RadioCarousel)