import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import Banner from "./Slider/Banner";
import PromoBanner from "./Slider/Promobanner";
// import Outstreamad from "./Slider/Outstreamad";
import Carousel from "./Slider/Carousel";
import myplexAPI, { URL_MENU } from "../api/myplexAPI";
//import { Fingerprint } from "../utils/fingerprint";
import SEO from "../common/helmet";
import "../assets/fonts/Roboto-Regular.css";
import "../assets/fonts/Roboto-Medium.css";
//import indexedDB from "../utils/indexedDB";
// import Loader from "../utils/loader";
import Loader from "../../commonfiles/loader";
import LazyLoad from "react-lazyload";
import indexedDB from "../utils/indexedDB";
// import ReactFlowplayer from "./Player/ReactFlowplayer";
import VideoJsPlayer from "./Player/VideoJsPlayer";
import SingleBanner from "./Slider/SingleBanner";
import { Button, FormGroup, Modal } from "react-bootstrap";
import ParseImgURL from "../utils/parseImgURL";
import { Link } from "react-router-dom";
// import {getURLName} from "../utils/urlMenu";
// import {slugify} from "../utils/stringSlugify";
import { getURLName } from "../../commonfiles/urlMenu";
import { slugify } from "../../commonfiles/stringSlugify";
import MmBanner from "./Slider/MmBanner";
import Desktopbanner from "./Slider/Desktopbanner";
import TopMenu from "./Header/TopMenu";
import EmptyWatchHistory from "./Header/EmptyWatchHistory";
import NewsTab from "./Slider/NewsTab";
import CenterMode from "./Slider/LayoutCenterCarousel";
import BreakingNews from "../general/Slider/BreakingNews";
import RadioCarousel from "./Slider/RadioCarousel";
import LayoutCenterBanner from "./Slider/LayoutCenterBanner";
//import GoogleAds from "./Slider/GoogleAds";
import SmallAd from "./Adlayouts/SmallAd";
// import AdNativeVideo from "./Adlayouts/AdNativeVideo";
// import AdInterstitial from "./Adlayouts/AdInterstitial";
// import AdBanner from "./Adlayouts/AdBanner";
// import AdNativeImage from "./Adlayouts/AdNativeImage";
//import VideoAd from "./Slider/VideoAd";

//import SpeedNews from "./Slider/SpeedNews";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Reels from "../components/Scroll";

class Render extends Component {
  _isMounted = false;
  _timOut;
  languageList = "";
  list = "";
  appLanguage = "";
  constructor(props) {
    super(props);
    this.state = {
      carosels: [],
      closePopup: false,
      popupTimeEnable: false,
      timeOutLimit: 0,
      repeat: 3,
      repeatCount: 0,
      isScrollPopup: false,
      showtimeOutPopup: false,
      carouselPosition: null,
      scrollTabs: [],
      timeOutTabs: [],
      scrollContentDetails: [],
      timeOutContentDetails: [],
      nestedMenu: [],
      topmenustatus: "topmenuhide",
      reels:""
    };
  }
  componentDidMount() {
    this.languageList = localStorage.getItem("selectedLanguageList");
    document.getElementById("root").scrollIntoView();
    this._isMounted = true;
    this.loadCurrentData(this.setPage());
    const _self = this;
    if (isMobile) {
      try {
        setTimeout(function () {
          _self._getPropertiesAll();
        }, 2000);
      } catch (e) {
        console.log(e);
      }
    }

    // this.showPopUpBasedOnTime();
  }
  componentWillUnmount() {
    this._isMounted = false;
    // debugger;
    clearInterval(this._timOut);
  }
  componentDidUpdate(prevsprops) {
    const _self = this;
    document.getElementById("root").scrollIntoView();
    if (prevsprops.match.params.Page !== this.props.match.params.Page) {
      clearInterval(this._timOut);
      this.setState({ carosels: [] });
      this.loadCurrentData(this.setPage());
      if (isMobile) {
        try {
          setTimeout(function () {
            _self._getPropertiesAll();
          }, 2000);
        } catch (e) {
          console.log(e);
        }
      }
      try {
        window.removeEventListener("scroll", this.scrollEventHandler);
      } catch (e) {
        console.log(e);
      }
      // this.loadJquery(this.loadCurrentData(this.setPage()));
    }
  }
  // getSsoClientId = () => {
  //   myplexAPI.getSsoAppInfo().then(response => {
  //     // console.log(response)
  //     if (response.data.code === 200 && response.data.status === 'SUCCESS') {

  //       window.location.href = `https://id.manoramaonline.com/authorize?scope=openid+email+profile+offline_access&response_type=code+token+id_token&client_id=${response.data.app_id}&redirect_uri=https://pwastagmmtv.myplex.com/login&state=1135778640&nonce=212883085`
  //     }

  //   })
  // }
  _getPropertiesAll = () => {
    try {
      // console.log('popUpJson', sessionStorage.getItem('pwaScrollPopupJson'));
      const pwaScrollPopupJson = sessionStorage.getItem("pwaScrollPopupJson");
      if (pwaScrollPopupJson !== null) {
        var popUpdata = pwaScrollPopupJson.replace(/\\/g, "");
        var items = JSON.parse(popUpdata).popup;
        const scroll_tab = items.scroll.page.some(
          (tab) => tab === window.location.pathname
        );
        // console.log(scroll_tab)
        let scroll_login = items.scroll.userStatus;
        if (scroll_login === "all") {
          scroll_login = true;
        } else {
          scroll_login = window.auth === true;
        }
        if (items.scroll.enabled && scroll_tab && scroll_login) {
          myplexAPI.getContentData(items.scroll.contentId).then((response) => {
            // console.log(response)
            if (response.data.status === "SUCCESS") {
              if (this._isMounted) {
                this.setState({
                  // isScrollPopup: true,
                  carouselPosition: items.scroll.carouselPosition,
                  scrollTabs: items.scroll.page,
                  scrollContentDetails: response.data.results,
                });
                this.popUpScrollEvent();
              }
            }
            // console.log(this.state)
          });
        }
        const timeout_tab = items.timeOut.page.some(
          (tab) => tab === window.location.pathname
        );
        let timeOutLogin = items.timeOut.userStatus;
        if (timeOutLogin === "all") {
          timeOutLogin = true;
        } else {
          timeOutLogin = window.auth === true;
        }
        if (items.timeOut.enabled && timeout_tab && timeOutLogin) {
          myplexAPI.getContentData(items.timeOut.contentId).then((response) => {
            if (response.data.status === "SUCCESS") {
              if (this._isMounted) {
                this.setState({
                  // isScrollPopup: true,
                  timeOutLimit: items.timeOut.timeOutLimit,
                  timeOutTabs: items.timeOut.page,
                  timeOutContentDetails: response.data.results,
                });
                this.showPopUpBasedOnTime();
              }
            }
            // console.log(this.state)
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  popUpScrollEvent = () => {
    try {
      const _self = this;
      const document_readystate_inter = window.setInterval(function () {
        if (document.readyState === "complete") {
          clearInterval(document_readystate_inter);
          window.addEventListener("scroll", _self.scrollEventHandler);
        }
      }, 1000);
    } catch (e) {
      console.log(e);
    }
  };
  scrollEventHandler = () => {
    const _self = this;
    let scrollPopUpEnableTabs = JSON.parse(
      sessionStorage.getItem("scrollPopUpEnableTabs")
    );
    const data = window.location.pathname;
    const carouselPosition = _self.state.carouselPosition;
    const el = document.getElementById(carouselPosition);
    // console.log(el)
    const scroll_view_point = _self.elementInViewport(el);
    let popUpVisibleOrNot = false;
    if (scrollPopUpEnableTabs !== null) {
      popUpVisibleOrNot = scrollPopUpEnableTabs.some((tab) => tab === data);
    }

    if (scroll_view_point && popUpVisibleOrNot === false) {
      _self.setState({
        isScrollPopup: true,
      });

      if (scrollPopUpEnableTabs === null) {
        scrollPopUpEnableTabs = [data];
      } else {
        scrollPopUpEnableTabs = JSON.parse(
          sessionStorage.getItem("scrollPopUpEnableTabs")
        );
        scrollPopUpEnableTabs.push(data);
      }
      sessionStorage.setItem(
        "scrollPopUpEnableTabs",
        JSON.stringify(scrollPopUpEnableTabs)
      );
    }
  };
  showPopUpBasedOnTime = () => {
    try {
      const self = this;
      // console.log(this.state.timeOutLimit)
      let timeOutPopUpEnableTabs = JSON.parse(
        sessionStorage.getItem("timeOutPopUpEnableTabs")
      );
      const data = window.location.pathname;
      let popUpVisibleOrNot = false;
      if (timeOutPopUpEnableTabs !== null) {
        popUpVisibleOrNot = timeOutPopUpEnableTabs.some((tab) => tab === data);
      }
      if (this.state.timeOutLimit !== 0 && popUpVisibleOrNot === false) {
        this._timOut = setTimeout(function () {
          self.setState({
            showtimeOutPopup: true,
          });
        }, this.state.timeOutLimit);
        if (timeOutPopUpEnableTabs === null) {
          timeOutPopUpEnableTabs = [data];
        } else {
          // Parse the serialized data back into an array of objects
          timeOutPopUpEnableTabs = JSON.parse(
            sessionStorage.getItem("timeOutPopUpEnableTabs")
          );
          timeOutPopUpEnableTabs.push(data);
        }
        sessionStorage.setItem(
          "timeOutPopUpEnableTabs",
          JSON.stringify(timeOutPopUpEnableTabs)
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
  elementInViewport = (el) => {
    if (el !== null) {
      var top = el.offsetTop;
      var left = el.offsetLeft;
      var width = el.offsetWidth;
      var height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }
      return (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        top + height <= window.pageYOffset + window.innerHeight &&
        left + width <= window.pageXOffset + window.innerWidth
      );
    } else {
      return false;
    }
  };
  setPage = () => {
    var page = this.props.match.params.Page;
    // eslint-disable-next-line
    if (page === "tv") {
      page = "TV";
    }
    // {
    page && (page = page.replace("-", " "));
    //}
    return this.ucFirst(page);
  };
  ucFirst = (string) => {
    if (string !== undefined) {
      //   var text = pagename.toLowerCase()
      // .split(' ')
      // .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      // .join(' ');
      // console.log(text)
      //   return string.charAt(0).toUpperCase() + string.slice(1);
      return string
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    }
  };

  navMenuResponse = (response, pagename) => {
    this.list = localStorage.getItem("selectedLanguageList");
    this.appLanguage = localStorage.getItem("selectedAppLanguageList");
    let result = response.data.results.find(
      (requestobj) => requestobj.title === pagename
    );
    result = result ? result : response.data.results[0];
    // console.group(result);
    myplexAPI
      .pullPageData(result.name, this.list, this.appLanguage)
      .then((response) => {
        if (this._isMounted) {
          this.setState({ carosels: response.data.results });
        }
      });
  };
  loadCurrentData(pagename) {
    const that = this;
    const nav_menu = window.nav_menu;
    const page = URL_MENU(nav_menu);
    // console.log("tt",pagename);
    localStorage.setItem("tabName",pagename);
    localStorage.setItem("os", "web");

    indexedDB(page)
      .then(function (response) {
        if (response !== false) {
          let result = response.find(
            (requestobj) => requestobj.title === pagename
          );
          // console.log(result);
          result = result ? result : response[0];
          const menu = URL_MENU(result.name);

          indexedDB(menu)
            .then(function (response) {
              if (response !== false) {
                that.setState({ carosels: response });
              } else {
                if (
                  localStorage.getItem("selected") === true &&
                  that.languageList !== null
                ) {
                  that._fetchCarousels(pagename, nav_menu, that.languageList);
                } else {
                  that._fetchCarousels(pagename, nav_menu, that.languageList);
                }
              }
            })
            .catch(function (err) {
              if (
                localStorage.getItem("selected") === true &&
                that.languageList !== null
              ) {
                that._fetchCarousels(pagename, nav_menu, that.languageList);
              } else {
                that._fetchCarousels(pagename, nav_menu, that.languageList);
              }
            });
        } else {
          if (
            localStorage.getItem("selected") === true &&
            that.languageList !== null
          ) {
            if (
              localStorage.getItem("selected") === true &&
              that.languageList !== null
            ) {
              that._fetchCarousels(pagename, nav_menu, that.languageList);
            } else {
              that._fetchCarousels(pagename, nav_menu, that.languageList);
            }
          } else {
            if (
              localStorage.getItem("selected") === true &&
              that.languageList !== null
            ) {
              that._fetchCarousels(pagename, nav_menu, that.languageList);
            } else {
              that._fetchCarousels(pagename, nav_menu, that.languageList);
            }
          }
        }
      })
      .catch(function (err) {
        console.log(err);
        if (
          localStorage.getItem("selected") === true &&
          that.languageList !== null
        ) {
          that._fetchCarousels(pagename, nav_menu, that.languageList);
        } else {
          that._fetchCarousels(pagename, nav_menu, that.languageList);
        }
      });
  }
  _fetchCarousels = (pagename, nav_menu, list) => {
    this.list = localStorage.getItem("selectedLanguageList");
    this.appLanguage = localStorage.getItem("selectedAppLanguageList");

    // var text = pagename.toLowerCase()
    // .split(' ')
    // .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    // .join(' ');
    // console.log(text)

    myplexAPI
      .pullPageData(nav_menu, this.list, this.appLanguage)
      .then((response) => {
        //console.log(response)
        let result = response.data.results
          ? response.data.results.find(
              (requestobj) => requestobj.title === pagename
            )
          : [];
        //  console.log(result)
        // myplexAPI.storeIndexedDb(
        //   response.request.responseURL,
        //   response.data.results
        // );
        if(!result){
          localStorage.setItem("tabName","home");
        }
        result = result ? result : response.data.results[0];
        this.setState({reels: result.title})
        myplexAPI
          .pullPageData(result.name, this.list, this.appLanguage)
          .then((response) => {
            if (this._isMounted) {
              if (
                response &&
                response.data &&
                response.data.results &&
                response.data.results.length > 0
              ) {
                // console.log(pagename);
                if (response.data.results[0].layoutType === "nestedCarousel") {
                  if (result.bgColor === "#FFFFFF") {
                    document.body.classList.remove("dark-theme");
                    document.body.classList.add("white-theme");
                  } else {
                    document.body.classList.add("dark-theme");
                    document.body.classList.remove("white-theme");
                  }
                  this.setState({
                    nestedMenu: response.data.results,
                    topmenustatus: "topmenushow ",
                  });
                  myplexAPI
                    .pullPageData(
                      response.data.results[0].name,
                      this.list,
                      this.appLanguage
                    )
                    .then((response) => {
                      this.setState({ carosels: response.data.results });
                    });
                } else {
                  // if (pagename === "News") {
                  //   document.body.classList.add("white-theme");
                  //   document.body.classList.remove("dark-theme");
                  // } else {
                  //   document.body.classList.add("dark-theme");
                  //   document.body.classList.remove("white-theme");
                  // }

                  this.setState({
                    carosels: response.data.results,
                    nestedMenu: [],
                    topmenustatus: "topmenuhide",
                  });
                }
              }
            }
          });
      });
  };
  changeTopMenu = (name) => {
    myplexAPI
      .pullPageData(name, this.list, this.appLanguage)
      .then((response) => {
        if (this._isMounted) {
          if (
            response &&
            response.data &&
            response.data.results &&
            response.data.results.length > 0
          ) {
            if (response.data.results[0].layoutType === "nestedCarousel") {
              this.setState({
                nestedMenu: response.data.results,
                topmenustatus: "topmenushow",
              });
              myplexAPI
                .pullPageData(
                  response.data.results[0].name,
                  this.list,
                  this.appLanguage
                )
                .then((response) => {
                  this.setState({ carosels: response.data.results });
                });
            } else {
              this.setState({
                carosels: response.data.results,
              });
            }
          }
        }
        // myplexAPI.storeIndexedDb(
        //   response.request.responseURL,
        //   response.data.results
        // );
      });
  };
  _getnestedcarosuls = (name) => {
    this.setState({ carosels: [] });
    myplexAPI
      .pullPageData(name, this.list, this.appLanguage)
      .then((response) => {
        //  console.log(response)
        if (this._isMounted) {
          if (response && response.data && response.data.results) {
            this.setState({ carosels: response.data.results });
          }
        }
        // myplexAPI.storeIndexedDb(
        //   response.request.responseURL,
        //   response.data.results
        // );
      });
  };
  carosels = () => {
    let carousel_count = 0;
    return (
      this.state.carosels.length > 0 && (
        <div>
          {this.state.carosels.map((carosel, key) => {
            // console.log(this.state.carosels[key].layoutType,carosel)
            const layoutType = carosel.layoutType;
            carousel_count = carousel_count + 1;

            //console.log(layoutType, carosel.shortDesc)

            switch (true) {
              case layoutType === "AdBannerbtwrailsSmall":
                return (
                  <LazyLoad height={200} offset={100} key={key}>
                    <SmallAd layoutType={layoutType} data={carosel} />
                  </LazyLoad>
                );
              case layoutType === "AdNativeVideo":
                return null;
                // <LazyLoad height={200} offset={100} key={key}>
                //   <SmallAd layoutType={layoutType} data={carosel}/>
                // </LazyLoad>
              case layoutType === "AdBannerbtwrailsMedium":
                return null;
                // <LazyLoad height={200} offset={100} key={key}>
                //   <SmallAd layoutType={layoutType} data={carosel}/>
                // </LazyLoad>
              case layoutType === "AdBannerbtwrailsLarge":
                return null;
                // <LazyLoad height={200} offset={100} key={key}>
                //   <SmallAd layoutType={layoutType} data={carosel}/>
                // </LazyLoad>
              case layoutType === "AdNativeImage":
                return null;
                // return   <LazyLoad height={200} offset={100} key={key}><Outstreamad key={key}/></LazyLoad> ;
                // <LazyLoad height={200} offset={100} key={key}>
                //   <SmallAd layoutType={layoutType} data={carosel}/>
                // </LazyLoad>
              case layoutType === "AdMediumLayout":
                return null;
                // <LazyLoad height={200} offset={100} key={key}>
                //   <AdNativeImage layoutType={layoutType} />
                // </LazyLoad>
              case layoutType === "banner" ||
                layoutType === "portraitBanner" ||
                layoutType === "squareBanner":
                if (isMobile) {
                  return (
                    <LazyLoad height={200} offset={100} key={key}>
                      <Banner
                        caroselinfo={carosel}
                        key={key}
                        layoutType={carosel.layoutType}
                        texture={carosel.texture}
                        carousel_count={carousel_count}
                      />{" "}
                    </LazyLoad>
                  );
                } else {
                  return (
                    <LazyLoad height={200} offset={100} key={key}>
                      <Desktopbanner
                        caroselinfo={carosel}
                        key={key}
                        layoutType={carosel.layoutType}
                        texture={carosel.texture}
                        carousel_count={carousel_count}
                      />{" "}
                    </LazyLoad>
                  );
                }
              case layoutType === "horizontal3DListBigItem":
                if (isMobile) {
                  return (
                    <LazyLoad height={200} offset={100} key={key}>
                      {" "}
                      <MmBanner
                        caroselinfo={carosel}
                        key={key}
                        layoutType={carosel.layoutType}
                        texture={carosel.texture}
                        carousel_count={carousel_count}
                      />{" "}
                    </LazyLoad>
                  );
                } else {
                  return (
                    <LazyLoad height={200} offset={100} key={key}>
                      {" "}
                      <LayoutCenterBanner
                        caroselinfo={carosel}
                        key={key}
                        layoutType={carosel.layoutType}
                        texture={carosel.texture}
                        carousel_count={carousel_count}
                      />{" "}
                    </LazyLoad>
                  );
                }
              case layoutType === "autoPlaySingleBanner" &&
                carosel.shortDesc === "100":
                //  &&
                // window.auth:
                return (
                  <LazyLoad height={200} offset={100} key={key}>
                    
                    <VideoJsPlayer
                      caroselinfo={carosel}
                      key={key}
                      texture={carosel.texture}
                      carousel_count={carousel_count}
                    />
                  </LazyLoad>
                );
              case layoutType === "autoPlaySingleBanner" &&
                carosel.shortDesc !== "100":
                return (
                  <LazyLoad height={200} offset={100} key={key}>
                    <SingleBanner
                      caroselinfo={carosel}
                      key={key}
                      layoutType={carosel.layoutType}
                      texture={carosel.texture}
                      carousel_count={carousel_count}
                    />
                  </LazyLoad>
                );
              // case layoutType === "autoPlaySingleBanner" &&
              //   carosel.shortDesc === "100" &&
              //   window.auth === false:
              //   return (
              //     <LazyLoad height={200} offset={100} key={key}>
              //       <SingleBanner
              //         caroselinfo={carosel}
              //         key={key}
              //         layoutType={carosel.layoutType}
              //         texture={carosel.texture}
              //       />
              //     </LazyLoad>
              //   );
              case layoutType === "promoBanner":
                return (
                  
                  <LazyLoad height={200} offset={100} key={key}>
                    <PromoBanner
                      caroselinfo={carosel}
                      key={key}
                      layoutType={carosel.layoutType}
                      texture={carosel.texture}
                      carousel_count={carousel_count}
                    />
                  </LazyLoad>
                );
              case layoutType === "singleBannerItem":
                return (
                  <LazyLoad height={200} offset={100} key={key}>
                    <SingleBanner
                      caroselinfo={carosel}
                      key={key}
                      layoutType={carosel.layoutType}
                      texture={carosel.texture}
                      carousel_count={carousel_count}
                    />
                  </LazyLoad>
                );
              case layoutType === "weeklyTrendingBigItem":
                return (
                  <LazyLoad height={200} offset={100} key={key}>
                    <NewsTab
                      carousel_count={carousel_count}
                      caroselinfo={carosel}
                      key={key}
                      layoutType={carosel.layoutType}
                      texture={carosel.texture}
                    />
                  </LazyLoad>
                );
              case layoutType === "weeklyTrendingMediumItem":
                return (
                  <LazyLoad height={200} offset={100} key={key}>
                    <BreakingNews
                      carousel_count={carousel_count}
                      caroselinfo={carosel}
                      key={key}
                      layoutType={carosel.layoutType}
                      texture={carosel.texture}
                    />
                  </LazyLoad>
                );
              case layoutType === "weeklyTrending3DListBigItem":
                return (
                  <LazyLoad height={200} offset={100} key={key}>
                    <CenterMode
                      carousel_count={carousel_count}
                      caroselinfo={carosel}
                      key={key}
                      layoutType={carosel.layoutType}
                      texture={carosel.texture}
                    />
                  </LazyLoad>
                );
              case layoutType === "roundedArtistCarousel":
                return (
                  <LazyLoad height={200} offset={100} key={key}>
                    <RadioCarousel
                      carousel_count={carousel_count}
                      caroselinfo={carosel}
                      key={key}
                      layoutType={carosel.layoutType}
                      texture={carosel.texture}
                    />
                  </LazyLoad>
                );
                case layoutType === "shortVideos" :
                  return (
                    <LazyLoad height={200} offset={100} key={key}>
                      <Reels
                        caroselinfo={carosel}
                        key={key}
                        layoutType={carosel.layoutType}
                        texture={carosel.texture}
                        carousel_count={carousel_count}
                      />{" "}
                    </LazyLoad>
                  );
                case layoutType === "continueWatching" && !localStorage.getItem("Logged-in"): 

                return null ;
              default:
               
                return (
                  <LazyLoad height={200} offset={100} key={key}>
                    <Carousel
                      carousel_count={carousel_count}
                      caroselinfo={carosel}
                      key={key}
                      layoutType={carosel.layoutType}
                      texture={carosel.texture}
                      changeTopMenu={this.changeTopMenu}
                    />
                    {/* <NewsTab carousel_count={carousel_count} caroselinfo={carosel}
                  key={key} layoutType={carosel.layoutType} texture={carosel.texture} /> */}
                  </LazyLoad>
                );
                
            }
           
          })}
        </div>
      )
    );
  };
  handleCloseScrollPopup = () => {
    this.setState({
      isScrollPopup: false,
      closePopup: true,
    });
  };
  handleCloseTimeOutPopup = () => {
    this.setState({
      showtimeOutPopup: false,
    });
  };
  render() {
    // console.log(this.state);

    return (
      <div
        className={this.state.reels == "Minis" ? ` ${this.state.topmenustatus}  reelspwa` : `sliderbody ${this.state.topmenustatus} topheader_banner`}
      >
        <SEO
          title=""
          meta_description="Enjoy movies, online series, live tv & more with manoramamax"
        />
        {this.state.nestedMenu.length > 0 && (
          <TopMenu
            nestedData={this.state.nestedMenu}
            getcarosuls={this._getnestedcarosuls}
          ></TopMenu>
        )}
        {this.state.carosels.length > 0 ? (
          this.carosels()
        ) : this.setPage() === "Watch History" ? (
          <EmptyWatchHistory />
        ) : (
          <Loader />
        )}
        {this.state.scrollContentDetails.length > 0 && (
          <Modal
            show={this.state.isScrollPopup}
            onHide={this.handleCloseScrollPopup}
            className="mobile_signin watchnow_modal_section"
          >
            <div className="signin_modal signin-part watchnow_modal">
              <Modal.Header closeButton> </Modal.Header>
              <Modal.Body>
                <div className="watchnow_title">
                  <label className="wondering">Wondering what to watch?</label>
                  <label className="recommend">We recommend</label>
                </div>
                {this.state.isScrollPopup && (
                  <React.Fragment>
                    <FormGroup>
                      <div className="onscroll_modal">
                        <img
                          alt="notification"
                          src={ParseImgURL(
                            this.state.scrollContentDetails[0].images.values,
                            "hdpi",
                            "coverposter"
                          )}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="watchnow_btn">
                        <Link
                          to={{
                            pathname: `/${getURLName(
                              this.state.scrollContentDetails[0],
                              this.state.pageName
                            )}/detail/${
                              this.state.scrollContentDetails[0].generalInfo._id
                            }/${slugify(
                              this.state.scrollContentDetails[0].generalInfo
                                .title
                            )}`,
                            // search: `?videotype=${caroselimg.generalInfo.type}`
                          }}
                        >
                          <Button bsStyle="success"> Watch Now </Button>
                        </Link>
                      </div>
                    </FormGroup>
                  </React.Fragment>
                )}
              </Modal.Body>
            </div>
          </Modal>
        )}
        {this.state.timeOutContentDetails.length > 0 && (
          <Modal
            show={this.state.showtimeOutPopup}
            onHide={this.handleCloseTimeOutPopup}
            className="mobile_signin watchnow_modal_section"
          >
            <div className="signin_modal signin-part watchnow_modal">
              <Modal.Header closeButton> </Modal.Header>
              <Modal.Body>
                <div className="watchnow_title">
                  <label className="wondering">Wondering what to watch?</label>
                  <label className="recommend">We recommend</label>
                </div>
                {this.state.showtimeOutPopup && (
                  <React.Fragment>
                    <FormGroup>
                      <div className="onscroll_modal">
                        <img
                          alt="notification"
                          src={ParseImgURL(
                            this.state.timeOutContentDetails[0].images.values,
                            "hdpi",
                            "coverposter"
                          )}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div className="watchnow_btn">
                        <Link
                          to={{
                            pathname: `/${getURLName(
                              this.state.timeOutContentDetails[0],
                              this.state.pageName
                            )}/detail/${
                              this.state.timeOutContentDetails[0].generalInfo
                                ._id
                            }/${slugify(
                              this.state.timeOutContentDetails[0].generalInfo
                                .title
                            )}`,
                            // search: `?videotype=${caroselimg.generalInfo.type}`
                          }}
                        >
                          <Button bsStyle="success">Watch Now</Button>
                        </Link>
                      </div>
                    </FormGroup>
                  </React.Fragment>
                )}
              </Modal.Body>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default Render;
