import React, { Component } from "react";
import Img from "../Uicomponents/Img";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import StarRatings from "react-star-ratings";

import { Link } from "react-router-dom";
import myplexAPI, { URL_CAROUSELCONTENT, URL_MENU } from "../../api/myplexAPI";
import "./Carousel.css";
import ParseImgURL from "../../utils/parseImgURL";
import watchlist_remove_icon from "../../assets/images/watchlist_remove_icon.png";
// import secureImages from "../../utils/secureImages";
import secureImages from "../../../commonfiles/secureImages";
import indexedDB from "../../utils/indexedDB";
// import { slugify } from "../../utils/stringSlugify";
import { slugify } from "../../../commonfiles/stringSlugify";
//import { carouselPatnerLogos } from "../../utils/partnerLogos";
//import LazyLoad from "react-lazyload";
// import { getURLName } from "../../utils/urlMenu";
import { getURLName } from "../../../commonfiles/urlMenu";
import { withRouter } from "react-router";
import placeholder_vertical from "../../assets/images/videos___shows_placeholder.png";
import { isMobile } from "react-device-detect";
//import viewall_arrow from "../../assets/images/view_all_arrow.png";
import view_all_arrow_mm from "../../assets/images/view_all_arrow_mm.png";
import carousel_freetag from "../../assets/images/freetag_thumbnail.png";
import premium_badge_lock from "../../assets/images/premium_badge_lock.png";
// import ParseImgURL from "../../utils/parseImgURL";
import sessionFun from "../../../commonfiles/SessionStorage";


class Carousel extends Component {
  list = "";
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      caroselimages: [],
      pageName: "home",
      trackingId: "",
    };
    this.carousel_info_name = this.props.caroselinfo.name;
    this._isMounted = false;
    this.auth = localStorage.getItem("Logged-in");
  }
  componentDidMount() {
    this._isMounted = true;
    // console.log(this.carousel_info_name);
    const page_name = window.location.pathname.split("/")[1];
    if (page_name !== "") {
      this.setState({
        pageName: page_name,
      });
      // if (page_name === 'Watch-History') {
      //   this.getSsoClientId();

      // }
    }
    this.loadCarouselsData();
  }
  componentDidUpdate(prevsprops) {
    if (this.props.caroselinfo.name !== prevsprops.caroselinfo.name) {
      this.loadCarouselsData();
    }
  }
  componentWillUnmount() {
    localStorage.removeItem("Languagechecklist", "");

    this._isMounted = false;
  }

  loadCarouselsData() {
    // eslint-disable-next-line
    {
      this.carousel_info_name !== "portalMobile_Discovery" &&
      this.carousel_info_name !== "portaldiscovery" &&
      this.carousel_info_name !== "portalMobileDiscovery" &&
      this.carousel_info_name !== "Portal_popular_genres" &&
      this.carousel_info_name !== "Portal_popular_languages"
        ? this._getCarouselsData(1)
        : this._getCarouselsData(0);
    }
  }
  _removeWatchlist = (Id, Type, e, i) => {
    // var newdata = this.state[carosulType].filter(item => item._id !== Id);
    // this.setState({
    //   [carosulType]: newdata
    // });
    e.stopPropagation();
    // var array = [...this.state.caroselimages]; // make a separate copy of the array
    // // console.log(Id);
    //  array = array.filter(i => i._id !== Id)(i);

    //   this.setState({caroselimages: this.setState({caroselimages: this.state.caroselimages.filter(function(c) {
    //     return c._id !== Id
    // })})
    // this.setState({caroselimages: this.state.caroselimages.filter(function(c) {
    //   return c._id !== Id
    // })

    // });
    // });
    // }
    const caroselimages = [...this.state.caroselimages];
    caroselimages.splice(i, 1);

    this.setState({ caroselimages: caroselimages });

    //   this.setState({caroselimages: this.state.caroselimages.filter(function(person) {
    //     return person !== e.target.value
    // })});

    myplexAPI.playerStatus(Id, "delete", Type, 0, "").then((response) => {
      // e.parentNode.removeChild(e);
      // console.log(response);
    });
  };
  _getCarouselsData = (e) => {
    const that = this;
    let url = "";
    const carousel_name = this.props.caroselinfo.name;
    // console.log(carousel_name)
    const network = window.navigator.onLine;
    if (e === 1) {
      url = URL_CAROUSELCONTENT(
        carousel_name,
        1,
        10,
        this.props.caroselinfo.modified_on
      );
    } else {
      url = URL_MENU(carousel_name);
    }
    // console.log(carousel_name,carousel_name.match(/MyReco/g))
    if (
      carousel_name !== "continue_watching_portal_mobile" ||
      carousel_name !== "IMT_portal_continue_watching" ||
      carousel_name !== "portal_continue_watching" ||
      carousel_name !== "continueWatching_1Pwa" ||
      carousel_name.match(/MyReco/g) === null
    ) {
      // console.log(carousel_name)
      indexedDB(url).then(function (response) {
        if (response !== false && that._isMounted) {
          that.setState({
            caroselimages: response,
          });
        } else {
          that._fetchCarousels(e, carousel_name);
        }
      });
    } else {
      if (network === true) {
        this._fetchCarousels(e, carousel_name);
      }
    }
  };
  _fetchCarousels = (e, carousel_name) => {
    const that = this;
    if (e === 1) {
      if (
        carousel_name === "continue_watching_portal_mobile" ||
        carousel_name === "IMT_portal_continue_watching" ||
        carousel_name === "portal_continue_watching" ||
        carousel_name === "continueWatching_1Pwa" ||
        carousel_name.match(/MyReco/g) !== null
      ) {
        myplexAPI
          .getContinueWatchingData(carousel_name, 1, 10)
          .then((response) => {
            // console.log(carousel_name, response);

            if (response !== null) {
              if (response.data !== null && this._isMounted) {
                that._responseData(response, 1);
                this.setState({
                  trackingId: response.data.trackingId,
                });
              }
            }
          });
      } else {
        this.list = localStorage.getItem("selectedLanguageList");
        myplexAPI
          .getCarouselData(
            carousel_name,
            1,
            10,
            this.props.caroselinfo.modified_on
          )
          .then((response) => {
            // console.log(carousel_name, response);
            that._responseData(response);
          });
      }
    } else {
      this.list = localStorage.getItem("selectedLanguageList");
      this.appLanguage = localStorage.getItem("selectedAppLanguageList");

      myplexAPI
        .pullPageData(carousel_name, this.list, this.appLanguage)
        .then((response) => {
          // console.log(response);
          that._responseData(response);
        });
    }
  };
  _responseData = (response, flag = 0) => {
    // console.log(response.data.status)
    if (
      response.data &&
      response.data.status &&
      response.data.status === "SUCCESS" &&
      this._isMounted
    ) {
      this.setState({
        caroselimages: response.data.results,
      });
      if (flag === 0) {
        myplexAPI.storeIndexedDb(
          response.request.responseURL,
          response.data.results
        );
      }
    }
  };
  decodeHTMLEntities = (text) => {
    var entities = {
      amp: "&",
      apos: "'",
      lt: "<",
      gt: ">",
      quot: '"',
      nbsp: "\xa0",
    };
    var entityPattern = /&([a-z]+);/gi;

    return text.replace(entityPattern, function (match, entity) {
      entity = entity.toLowerCase();
      if (entities.hasOwnProperty(entity)) {
        return entities[entity];
      }

      // return original string if there is no matching entity (no replace)
      return match;
    });
  };

  // carouselClick = (URL, type, title, images, description) => {
  //   var src;
  //   if (isMobile) {
  //     src = ParseImgURL(images.values, "mdpi", "coverposter"); //images.values[0].link;
  //   } else {
  //     src = ParseImgURL(images.values, "xxhdpi", "coverposter");
  //   }

  //   localStorage.setItem("actorImage", src);
  //   localStorage.setItem("actorType", type);
  //   localStorage.setItem("personDecription", description);

  //   if (this.auth) {
  //     if (type !== "person" && type !== "actor") {
  //       // this.props.history.push(URL);
  //     } else {
  //       this.props.history.push(`/Actorprofile/${title}`);
  //     }
  //   } else {
  //     if (type !== "person" && type !== "actor") {
  //       localStorage.setItem("redirectUrl", URL);
  //       // this.props.history.push(URL);

  //       // this.props.history.push("/login");
  //     } else {
  //       this.props.history.push(`/Actorprofile/${title}`);
  //     }

  //     //  this.props.history.push("/login");
  //   }
  // };
  carouselClick = (URL, type, title, images, description,i,id) => {
    var src;
    // console.log("clickedcarousel")
    if (isMobile) {
      src = ParseImgURL(images.values, "mdpi", "coverposter"); //images.values[0].link;
    } else {
      src = ParseImgURL(images.values, "xxhdpi", "coverposter");
    }

    localStorage.setItem("actorImage", src);
    localStorage.setItem("actorType", type);
    localStorage.setItem("personDecription", description);
    localStorage.setItem("source details", this.props.caroselinfo.title);
    localStorage.setItem("source", "carousel");
    sessionFun(
      this.props.carousel_count,
      i + 1,
      this.state.pageName,
      "banner",
      this.props.caroselinfo.title,
      id
    );

    if (this.auth) {
      if (type !== "person" && type !== "actor") {
        // if(this.props.caroselinfo.name == "continue_watching_portal_mobile" ||
        // this.props.caroselinfo.name == "IMT_portal_continue_watching" ||
        // this.props.caroselinfo.name == "portal_continue_watching" ||
        // this.props.caroselinfo.name == "continueWatching_1Pwa"){
        //   URL= URL+"?continuewatch=true";
        // }
        // this.props.history.push(URL);
      } else {
        // this.props.history.push(`/Actorprofile/${title}`);
      }
    } else {
      if (type !== "person" && type !== "actor") {
        localStorage.setItem("redirectUrl", URL);
        // this.props.history.push(URL);

        // this.props.history.push("/login");
      } else {
        // this.props.history.push(`/Actorprofile/${title}`);
      }

      //  this.props.history.push("/login");
    }
  };
  viewall = () => {
    localStorage.setItem("texture", JSON.stringify(this.props.texture));
  };
  caroselimages = () => {
    // console.log(this.props.caroselinfo.name);
    return this.props.layoutType !== "nestedCarousel"
      ? this.state.caroselimages.map((caroselimg, i) => {
          // sessionFun(
          //   this.props.carousel_count,
          //   i + 1,
          //   this.state.pageName,
          //   "carosuel",
          //   this.props.caroselinfo.title,
          //   caroselimg.generalInfo._id
          // );
          //console.log("at",caroselimg.content.actionType)
          //console.log("url",caroselimg.content.actionURL)
          var url = `/${getURLName(caroselimg, this.state.pageName)}/detail/${
            caroselimg.generalInfo._id
          }/${slugify(caroselimg.generalInfo.title)}`;

          if (caroselimg.generalInfo.type == "tvepisode") {
            var beautifytitle =
              caroselimg.generalInfo.title &&
              caroselimg.generalInfo.title.split("|");
            var t = beautifytitle[0];
            if (beautifytitle[1]) {
              t = t + beautifytitle[1];
            }
            //  console.log(caroselimg.globalServiceName);
            //  console.log(caroselimg.generalInfo._id);
            //  console.log(beautifytitle);
            url = `/${getURLName(caroselimg, this.state.pageName)}/detail/${
              caroselimg.generalInfo._id
            }/${slugify(caroselimg.globalServiceName)}/${slugify(t)}`;
            //  console.log(beautifytitle);
            //  console.log(url);
          }
          if (
            this.props.caroselinfo.name === "continue_watching_portal_mobile" ||
            this.props.caroselinfo.name === "IMT_portal_continue_watching" ||
            this.props.caroselinfo.name === "portal_continue_watching" ||
            this.props.caroselinfo.name === "continueWatching_1Pwa"
          ) {
            url = url + "?continuewatch=true";
          }
          if (caroselimg.content.actionType === "menu") {
            url = "#";
          }
          return (
            <div
              className="carousel_img item"
              // onClick={() => {
              //   if (caroselimg.content.actionType === "menu") {
              //     this.props.changeTopMenu(caroselimg.content.actionURL);
              //   } else {
              //     this.carouselClick(
              //       `/${getURLName(caroselimg, this.state.pageName)}/detail/${
              //         caroselimg.generalInfo._id
              //       }/${slugify(caroselimg.generalInfo.title)}`,
              //       caroselimg.generalInfo.type,
              //       caroselimg.generalInfo.title,
              //       caroselimg.images,
              //       caroselimg.generalInfo.description
              //     );
              //   }
              // }}
              key={caroselimg._id}
            >
              <Link
                key={caroselimg._id}
                to={url}
                onClick={() => {
                  if (caroselimg.content.actionType === "menu") {
                    this.props.changeTopMenu(caroselimg.content.actionURL);
                  } else {
                    this.carouselClick(
                      url,
                      caroselimg.generalInfo.type,
                      caroselimg.generalInfo.title,
                      caroselimg.images,
                      caroselimg.generalInfo.description,i,caroselimg.generalInfo._id
                    );
                  }
                }}
              >
                {!caroselimg.generalInfo.isSellable &&
                  caroselimg.generalInfo.contentRights &&
                  caroselimg.generalInfo.contentRights[0] === "avod" && (
                    <div className="carousel_freetag">
                      <img src={carousel_freetag} alt="carousel_freetag" />
                    </div>
                  )}
                <Img
                  caroselimg={caroselimg}
                  key={i}
                  banner={false}
                  layouttype={this.props.layoutType}
                  caroselname={this.props.caroselinfo.name}
                  observer={true}

                />
              </Link>
              {/* premium icon */}
              {(this.props.caroselinfo.name ===
                "continue_watching_portal_mobile" ||
                this.props.caroselinfo.name ===
                  "IMT_portal_continue_watching" ||
                this.props.caroselinfo.name === "portal_continue_watching" ||
                this.props.caroselinfo.name === "continueWatching_1Pwa") && (
                <div className="remove_item">
                  <img
                    src={watchlist_remove_icon}
                    alt="DeleteIcon"
                    onClick={(e) =>
                      this._removeWatchlist(
                        caroselimg._id,
                        caroselimg.generalInfo.type,
                        e,
                        i
                      )
                    }
                  />
                </div>
              )}
              {caroselimg.generalInfo.accessLabel && sessionStorage.getItem("userPacks") != null && JSON.parse(sessionStorage.getItem("userPacks")).length <= 0 && (
                <div className="premium_badge_lock">
                  <img src={premium_badge_lock} alt="premium_badge_lock" />
                </div>
              )}
              {this.props.texture !== null && this.props.texture.length > 0 && (
                <div className="carousel_titles">
                  {this.props.texture.find(
                    (obj) => obj.metadata === "title"
                  ) !== null &&
                  this.props.texture.find((obj) => obj.metadata === "title") !==
                    undefined ? (
                    <h1 className="movie_title">
                      {caroselimg.generalInfo.title}
                    </h1>
                  ) : // <h1></h1>
                  null}

                  <div className="movie_details-div">
                    <ul>
                      {this.props.texture.find(
                        (obj) => obj.metadata === "language"
                      ) !== null &&
                      this.props.texture.find(
                        (obj) => obj.metadata === "language"
                      ) !== undefined &&
                      caroselimg.content.language.length > 0 ? (
                        <li>{caroselimg.content.language[0]}</li>
                      ) : (
                        ""
                      )}
                      {this.props.texture.find(
                        (obj) => obj.metadata === "genre"
                      ) !== null &&
                      this.props.texture.find(
                        (obj) => obj.metadata === "genre"
                      ) !== undefined &&
                      caroselimg.content.genre.length > 0 ? (
                        <li>{caroselimg.content.genre[0].name}</li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                  {this.props.texture.find(
                    (obj) => obj.metadata === "rating"
                  ) !== null &&
                  this.props.texture.find(
                    (obj) => obj.metadata === "rating"
                  ) !== undefined ? (
                    <div className="app_rating">
                      {parseInt(caroselimg.content.contentRating) > 0 ? (
                        <StarRatings
                          rating={parseInt(caroselimg.content.contentRating)}
                          starRatedColor="#ffcc33"
                          // changeRating={this.changeRating}
                          numberOfStars={5}
                          name="star-container"
                          className="rated"
                        />
                      ) : (
                        <ul id="star-rating">
                          <li className="rated"></li>
                          <li className="rated"></li>
                          <li className="rated"></li>
                          <li className="rated"></li>
                          <li></li>
                        </ul>
                      )}
                    </div>
                  ) : (
                    // <StarRatings
                    //     rating={this.state.ratingValue}
                    //     starRatedColor="#f7b15b"
                    //     // changeRating={this.changeRating}
                    //     numberOfStars={5}
                    //     name='star-container'
                    //     className = "rated"
                    //     />
                    <div className="app_rating"></div>
                  )}
                  {/* { this.props.texture.find(obj => obj.metadata === "price") !== null && this.props.texture.find(obj => obj.metadata === "price") !== undefined ? <h1 className="payment_amount rupee">{bannerimg.packages.length && bannerimg.packages[0].priceDetails.length > 0  ? <span className="rupee_symbol">₹ {bannerimg.packages[0].priceDetails[0].price}</span> : ''}</h1>:null} */}
                  {caroselimg.generalInfo.isSellable &&
                    this.props.texture.find(
                      (obj) => obj.metadata === "price"
                    ) !== null &&
                    this.props.texture.find(
                      (obj) => obj.metadata === "price"
                    ) !== undefined && (
                      <h1 className="payment_amount rupee">
                        {caroselimg.packages.length > 0 &&
                        caroselimg.packages[0].priceDetails.length > 0 ? (
                          <span className="rupee_symbol">
                            ₹ {caroselimg.packages[0].priceDetails[0].price}
                          </span>
                        ) : (
                          ""
                        )}
                      </h1>
                    )}
                  {!caroselimg.generalInfo.isSellable &&
                    caroselimg.generalInfo.contentRights &&
                    caroselimg.generalInfo.contentRights[0] === "avod" &&
                    this.props.texture.find(
                      (obj) => obj.metadata === "price"
                    ) !== null &&
                    this.props.texture.find(
                      (obj) => obj.metadata === "price"
                    ) !== undefined && (
                      <h1 className="payment_amount rupee">
                        <span className="rupee_symbol">Free</span>
                      </h1>
                    )}
                  {/* { caroselimg.generalInfo.isSellable && this.props.texture.find(obj => obj.metadata === "price") !== null && this.props.texture.find(obj => obj.metadata === "price") !== undefined ?  <h1 className="payment_amount rupee">{caroselimg.packages.length > 0 && caroselimg.packages[0].priceDetails.length > 0 ?<span className="rupee_symbol">₹ {caroselimg.packages[0].priceDetails[0].price}</span> : ''}</h1> : <h1 className="payment_amount rupee"><span className="rupee_symbol">Free</span></h1>} */}
                </div>
              )}
            </div>
          );
        })
      : this.state.caroselimages.map((caroselimg, i) => {
          // console.log(caroselimg.title);
          // console.log(caroselimg);

          // console.log(caroselimg.images.values[1].link);
          var neimg =
            caroselimg.images &&
            caroselimg.images[1] &&
            caroselimg.images[1].link
              ? caroselimg.images[1].link
              : placeholder_vertical;
          return (
            <Link
              to={{
                pathname: `/${this.state.pageName}/filter`,
                search: this.checkFilterValues(caroselimg.title),
              }}
              key={i}
            >
              <img
                src={secureImages(neimg)}
                alt={caroselimg.title}
                className="genres_languages"
              />
            </Link>
          );
        });
  };
  checkFilterValues = (title) => {
    return this.carousel_info_name === "Portal_popular_genres"
      ? `?type=movie&genres=${title}`
      : `?type=movie&filtervalue=${title}`;
  };
  getThumbnailLiveTv = (item) => {
    //console.table(item.images.values);
    return ParseImgURL(item.images.values, "mdpi", "thumbnail");
  };
  getPartnerLogo = (array) => {
    return array.find(function (e) {
      return e.profile === "hdpi";
    });
  };
  getclassName = (link) => {
    // var result = link.find(function(e) {
    //   return e.profile === "hdpi";
    // });
    var result = this.getPartnerLogo(link);
    let imgclass;
    let issquare = result.link.includes("square");
    let ishook = result.link.includes("hook");
    let ishungama = result.link.includes("hungama");
    let isarre = result.link.includes("arre");
    if (issquare) {
      imgclass = "squareimage";
    } else if (ishook) {
      imgclass = "hookimage";
    } else if (ishungama) {
      imgclass = "hungamaimage";
    } else if (isarre) {
      imgclass = "arreimage";
    } else {
      imgclass = "logo";
    }
    return imgclass;
  };
  sourceDetails = (source_details, trackingId) => {
    // console.log(trackingId)
    // console.log("sdetails".source_details)
    localStorage.setItem("source details", source_details);
    localStorage.setItem("source", "carousel");
    localStorage.setItem("trackingId", trackingId);
  };

  render() {
    let perpage = 0;
    // console.log(this.props.layoutType);
    if (
      this.props.layoutType === "horizontalListBigItem" ||
      this.props.layoutType === "previewCarousel" ||
      this.props.layoutType === "roundedArtistCarousel"
    ) {
      perpage = 10;
    } else if (this.props.layoutType === "nestedCarousel") {
      perpage = 8;
    } else if (this.props.layoutType === "newsNestedLayout") {
      perpage = 2;
    } else {
      perpage = 7;
    }

    // const options = {
    //   items: perpage,

    //   responsive: {
    //     320: {
    //       items: perpage === 2 ? 2 : perpage === 10 ? 2 : perpage === 8 ? 4 : 2,
    //       nav: true,
    //     },
    //     360: {
    //       items: perpage === 2 ? 2 : perpage === 10 ? 3 : perpage === 8 ? 4 : 2,
    //       nav: true,
    //     },
    //     481: {
    //       items: perpage === 2 ? 2 : perpage === 10 ? 3 : perpage === 8 ? 4 : 3,
    //       nav: false,
    //     },
    //     768: {
    //       items: perpage === 2 ? 2 : perpage === 10 ? 4 : perpage === 8 ? 5 : 4,
    //       nav: false,
    //     },
    //     1200: {
    //       items: perpage === 2 ? 2 : perpage === 10 ? 6 : perpage === 8 ? 4 : 4,
    //       nav: true,
    //     },
    //     1300: {
    //       items: perpage === 2 ? 2 : perpage === 10 ? 7 : perpage === 8 ? 4 : 4,
    //       nav: true,
    //     },
    //     1400: {
    //       items: perpage === 2 ? 2 : perpage === 10 ? 8 : perpage === 8 ? 4 : 4,
    //       nav: true,
    //     },
    //     1500: {
    //       items: perpage === 2 ? 2 : perpage === 10 ? 8 : perpage === 8 ? 6 : 6,
    //       nav: true,
    //     },
    //     1600: {
    //       items: perpage === 2 ? 2 : perpage === 10 ? 9 : perpage === 8 ? 7 : 7,
    //       nav: true,
    //     },
    //     1700: {
    //       items:
    //         perpage === 2 ? 2 : perpage === 10 ? 10 : perpage === 8 ? 7 : 8,
    //       nav: true,
    //     },
    //     1920: {
    //       items:
    //         perpage === 2 ? 2 : perpage === 10 ? 10 : perpage === 8 ? 7 : 8,
    //       nav: true,
    //     },
    //   },
    //   nav: true,
    //   navText: [
    //     "<div class='carousel-nav-btn carousel-prev-slide'></div>",
    //     "<div class='carousel-nav-btn carousel-next-slide'></div>",
    //   ],
    //   rewind: false,
    //   margin: 10,
    //   dots: false,
    //   autoplay: false,
    //   slideBy: 10,
    //   loop: false,
    //   touchDrag: true,
    //   mouseDrag: true,
    // };
    const settings = {
      className: "center",
      dots: false,
      infinite: false,
      speed: 500,
      margin: 10,
      slidesToShow: this.props.layoutType === "newsNestedLayout" ? 2 : 7,
      // slidesToScroll: 10,
      loop: false,

      responsive: [
        {
          breakpoint: 1920,
          settings: {
            // slidesToShow: perpage === 2 ? 2 : perpage === 10 ? 7 : perpage === 8 ? 4 : 4,
            // slidesToScroll: 4
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: perpage === 2 ? 2 : perpage === 10 ? 4 : perpage === 8 ? 4 : 3,
            slidesToScroll: 4,
            arrows: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: perpage === 2 ? 2 : perpage === 10 ? 3 : perpage === 8 ? 4 : 3,
            slidesToScroll: 3,
            arrows: false

          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: perpage === 2 ? 2 : perpage === 10 ? 3 : perpage === 8 ? 4 : 2,
            slidesToScroll: 3,
            arrows: false,
          }
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: perpage === 2 ? 2 : perpage === 10 ? 3 : perpage === 8 ? 4 : 2,
            slidesToScroll: 2,
            arrows: false,
          }
        }
      ]

    };

    // const events = {
    //   onDragged: function (event) {},
    //   onChanged: function (event) {},
    // };
    // console.log(this.props.caroselinfo)
    let title = this.props.caroselinfo.title;
    if (this.props.caroselinfo.altTitle) {
      title = this.props.caroselinfo.altTitle;
    }
    // console.log(title)
    return (
      <React.Fragment>
        {this.state.caroselimages.length > 0 && (
          <div className="carousel_slider_section">
            <div className="center_caousel_item">
          <div
            className={`carousel_body  ${
              this.props.layoutType === "newsNestedLayout" && !isMobile
                ? "nestedCarosul"
                : ""
            } ${
              this.props.layoutType === "newsNestedLayout" && isMobile
                ? "newsNestedLayout"
                : ""
            }`}
            id={this.props.carousel_count}
          >
            <div className="home_carosel_title" onClick={() => this.viewall()}>
              {this.props.caroselinfo.name !== "portalMobile_Discovery" &&
              this.props.caroselinfo.name !== "portaldiscovery" &&
              this.props.caroselinfo.name !== "portalMobileDiscovery" &&
              this.props.caroselinfo.name !== "Portal_popular_genres" &&
              this.props.caroselinfo.name !== "Portal_popular_languages" ? (
                <Link
                  to={{
                    pathname: `/${this.state.pageName}/inside/${this.props.caroselinfo.name}`,
                    search: `?name=${this.props.caroselinfo.title.replace(
                      / /g,
                      "-"
                    )}&layoutType=${this.props.layoutType}&title=${title}&cpos=${this.props.carousel_count}`,
                  }}
                >
                  {this.props.caroselinfo.showTitle && (
                    <React.Fragment>
                      <h1>{this.decodeHTMLEntities(title)}</h1>
                      {this.props.caroselinfo.enableShowAll && (
                      <div className="see_more_part">
                        {!isMobile && <p>See More</p>}
                        <img
                          src={view_all_arrow_mm}
                          className="view_all_arrow"
                          alt="viewall_arrow_img"
                          onClick={() =>
                            this.sourceDetails(
                              this.props.caroselinfo.title,
                              this.state.trackingId
                            )
                          }
                        />
                      </div>)}
                    </React.Fragment>
                  )}
                </Link>
              ) : (
                <h1 style={{ cursor: "default" }}>{title}</h1>
              )}
              <span className="home_viewall">
                {this.props.caroselinfo.name !== "portalMobile_Discovery" &&
                this.props.caroselinfo.name !== "portalMobileDiscovery" &&
                this.props.caroselinfo.name !== "portaldiscovery" &&
                this.props.caroselinfo.name !== "Portal_popular_genres" &&
                this.props.caroselinfo.name !== "Portal_popular_languages" &&
                this.state.caroselimages.length >= 10 ? (
                  <Link
                    to={{
                      pathname: `/${this.state.pageName}/inside/${this.props.caroselinfo.name}`,
                      search: `?name=${this.props.caroselinfo.title.replace(
                        / /g,
                        "-"
                      )}&layoutType=${this.props.layoutType}&title=${title}`,
                    }}
                  >
                    {/* <span className="home_viewall"  onClick={() => this.sourceDetails(this.props.caroselinfo.title,this.state.trackingId)}>View All</span> */}
                  </Link>
                ) : null}
              </span>
            </div>
            <div
              className="carosel_slider"
              onClick={() =>
                this.sourceDetails(
                  this.props.caroselinfo.title,
                  this.state.trackingId
                )
              }
            >
              <Slider {...settings}> {this.caroselimages()} </Slider>
              {/* <OwlCarousel
                className="owl-theme"
                items={perpage}
                responsive={options.responsive}
                nav={true}
                navText={[
                  "<div class='carousel-nav-btn carousel-prev-slide'></div>",
                  "<div class='carousel-nav-btn carousel-next-slide'></div>",
                ]}
                rewind={false}
                margin={10}
                dots={false}
                autoplay={false}
                slideBy={10}
                loop={false}
                touchDrag={true}
                mouseDrag={true}
              >
                {this.caroselimages()}
              </OwlCarousel> */}
            </div>
          </div>
          </div>
          </div>
        )}
        {this.state.caroselimages.length === 0 && (
          <div id={this.props.carousel_count} />
        )}
      </React.Fragment>
    );
  }
}
export default withRouter(Carousel);
